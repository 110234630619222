<template>
  <div class="b-home">
    <div class="background">
      <div class="home-user m-3 pr-3 pl-3 pt-1 pb-1 d-flex">
        <div class="home-user-nama">
          <h6><span class="bold">Hi, </span>{{ user.data.fullname }}</h6>
          <p class="nik">{{ user.data.nik }}</p>
          <p class="status_karyawan">{{ user.data.status_karyawan }}</p>
        </div>
        <div class="home-user-img">
          <router-link to="/profile">
            <img :src="user.data.foto" alt="" class="img-thumbnail" />
          </router-link>
        </div>
      </div>
      <div v-if="user.data.jabatan == 803 || user.data.jabatan == 936">
        <DashboardSecurity />
      </div>
      <div v-else>
        <Dashboard />
      </div>
    </div>
    <div class="p-3">
      <b-row
        class="bd-navigation d-flex justify-content-center mx-0 my-3"
        no-gutters
      >
        <b-col class="px-1 text-center">
          <router-link to="/presensi">
            <img
              src="@/assets/images/iconmenu/presensi2.png"
              alt="icon_baik_presensi"
              class="img-fluid w-100"
            />
            <span class="f-11">Presensi</span>
          </router-link>
        </b-col>
        <b-col class="px-1 text-center">
          <router-link to="/absensi">
            <img
              src="@/assets/images/iconmenu/absensi2.png"
              alt="icon_baik_absensi"
              class="img-fluid w-100"
            />
            <span class="f-11">Absensi</span>
          </router-link>
        </b-col>
        <b-col class="px-1 text-center">
          <router-link to="/info">
            <img
              src="@/assets/images/iconmenu/info2.png"
              alt="icon_baik_info"
              class="img-fluid w-100"
            />
            <span class="f-11">Info</span>
          </router-link>
        </b-col>
        <b-col class="px-1 text-center">
          <router-link to="/saldo">
            <img
              src="@/assets/images/iconmenu/saldo2.png"
              alt="icon_baik_saldo"
              class="img-fluid w-100"
            />
            <span class="f-11">Saldo</span>
          </router-link>
        </b-col>
        <b-col class="px-1 text-center">
          <router-link to="/kasbon">
            <img
              src="@/assets/images/iconmenu/kasbon2.png"
              alt="icon_baik_kasbon"
              class="img-fluid w-100"
            />
            <span class="f-11">Kasbon</span>
          </router-link>
        </b-col>
      </b-row>
      <b-row
        class="bd-navigation d-flex justify-content-center mx-0 my-3"
        no-gutters
      >
        <b-col
          v-if="
            user.data.role_id == 0 ||
            user.data.jabatan == 803 ||
            user.data.jabatan == 936
          "
          class="px-1 text-center"
        >
          <router-link to="/absen-security">
            <div>
              <img
                src="@/assets/images/iconmenu/security.png"
                alt="icon_baik_security"
                class="img-fluid w-100"
              />
            </div>
            <div>
              <span class="f-10 p-2 bold">Security</span>
            </div>
          </router-link>
        </b-col>
        <b-col v-if="user.data.role_id == 0" class="px-1 text-center">
          <router-link to="/regis-lokasi">
            <div>
              <img
                src="@/assets/images/iconmenu/reglokasi.png"
                alt="icon_baik_reglokasi"
                class="img-fluid w-100"
              />
            </div>
            <div>
              <span class="f-10 p-2 bold">RegKantor</span>
            </div>
          </router-link>
        </b-col>
        <b-col class="px-1 text-center"> </b-col>
        <b-col class="px-1 text-center"> </b-col>
        <b-col class="px-1 text-center"> </b-col>
      </b-row>
      <div class="bd-info bd-card rounded-3 bg-light">
        <div class="bd-card-title p-3 d-flex align-items-center">
          <b-icon icon="info" class="text-primary me-2" />
          <h6 class="f-14 bold" style="text-transform: uppercase">Info</h6>
        </div>
        <div class="bd-card-content">
          <div
            class="d-flex justify-content-center align-items-center flex-column p-5"
            v-if="info.loading"
          >
            <b-spinner
              class="f-12"
              type="grow"
              label="Spinning"
              style="background-color: #159895"
            ></b-spinner>
            <span class="f-11 mt-1 bold">Loading....</span>
          </div>
          <div v-else>
            <router-link
              :to="`/info/${i.id}`"
              class="bd-info-item p-2 border-bottom d-flex"
              v-for="(i, iIndex) in info.data"
              :key="`bii-${iIndex}`"
            >
              <div class="bdii-image">
                <img :src="i.gambar" alt="" />
              </div>
              <div class="bdii-content pl-2">
                <h5 class="text-dark f-13">
                  {{ i.judul }}
                </h5>
                <span class="text-dark">{{ dateFormat(i.created_date) }}</span>
              </div>
            </router-link>
            <div
              class="bd-info-see-all pt-2 pr-3 text-right d-flex justify-content-end"
            >
              <router-link to="/info" style="font-size: 13px">
                <h6 class="readmore f-12 bold">
                  Lihat Semua Info
                  <b-icon icon="skip-end-btn-fill"></b-icon></h6
              ></router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <b-textarea v-model="token"></b-textarea> -->
  </div>
</template>
<style>
.bold {
  font-weight: bold;
}
.f-10 {
  font-size: 10px !important;
}
.f-11 {
  font-size: 11px !important;
}
.f-12 {
  font-size: 12px !important;
}
.f-13 {
  font-size: 13px !important;
}
.f-14 {
  font-size: 14px !important;
}
.title {
  border: none !important;
}
</style>
<script>
import { getMessaging, getToken } from "firebase/messaging";
import { mapGetters } from "vuex";
import axios from "@/axios";
const messaging = getMessaging();
import Dashboard from "@/components/Dashboard.vue";
import DashboardSecurity from "@/components/DashboardSecurity.vue";
export default {
  components: {
    Dashboard,
    DashboardSecurity,
  },
  data() {
    return {
      info: {
        data: [],
        loading: false,
      },
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },
  mounted() {
    this.getInfo();
    this.getFbToken();
  },
  created() {},
  methods: {
    async getInfo() {
      this.info.loading = true;
      let url = `/information/info?nik=${this.user.data.nik}`;
      let header = {
        headers: {
          Token: this.user.token,
        },
      };
      let req = await axios.get(url, header);
      if (req.data.status) {
        this.info.data = req.data.msg;
        this.info.loading = false;
      } else {
        this.info.loading = false;
      }
    },
    dateFormat(date) {
      let theDate = new Date(date);
      let days = [
        "Minggu",
        "Senin",
        "Selasa",
        "Rabu",
        "Kamis",
        "Jumat",
        "Sabtu",
      ];
      let day = theDate.getDay();
      let d = theDate.getDate();
      let m = theDate.getMonth() + 1;
      let y = theDate.getFullYear();
      return `${days[day]}, ${d}-${m}-${y}`;
    },
    async setToken(token) {
      let url = `presence/update_fbtoken`;
      let header = {
        headers: {
          Token: this.user.token,
        },
      };
      let payload = new FormData();
      payload.append("fbtoken", token);
      payload.append("user_id", this.user.data.user_id);
      let req = await axios.post(url, payload, header);
      if (req.data.status) {
        console.log("Successfully set token");
      } else {
        console.log("Failed to set token");
      }
    },
    getFbToken() {
      try {
        Notification.requestPermission()
          .then((permission) => {
            if (permission === "granted") {
              console.log("Notification permission granted.");
              getToken(messaging, {
                vapidKey:
                  "BOGs6Dpg-Cosiv72qBatRNRxsVy7aVyDYAGH_WCIcdneNXtgikVP8_dX5Oc2Uj5ZJ2lzuPMOFazUCd2tlX6YI0o",
              })
                .then((currentToken) => {
                  if (currentToken) {
                    this.setToken(currentToken);
                  } else {
                    console.log(
                      "No registration token available. Request permission to generate one."
                    );
                  }
                })
                .catch((err) => {
                  console.log(
                    "An error occurred while retrieving token. ",
                    err
                  );
                });
            } else {
              console.log("Notification permission denied.");
            }
          })
          .catch((err) => {
            console.log("Unable to get token ", err);
          });
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>