<template>
  <div class="home-user-desc m-3 p-3">
    <div class="judul d-flex">
      <h6>Rekapitulasi |</h6>
      <p class="f-11"><i> Presensi</i></p>
    </div>
    <div class="list-chart">
      <div class="home-progress p-3 mt-2">
        <vue-ellipse-progress
          :size="100"
          :color="gradient"
          line-mode="in 3"
          :progress="progress"
          fontSize="13px"
          style="font-weight: bold; color: white"
          dash="10 0.9"
          dot="7 white"
        >
          <span class="f-12 bold" slot="legend-value" style="color: white">
          </span>
          <span class="f-10"
            >{{ this.presensi.data.totalHadir }} /
            {{ this.presensi.data.totalPeriode }}
          </span>
          <span class="f-10" slot="legend-caption">Data Presensi</span>
        </vue-ellipse-progress>
      </div>
      <div class="list-fp mt-4">
        <div class="mt-3">
          <div class="col-sm-12">
            <div class="progress-horz d-flex justify-content-between">
              <span style="font-size: 10px !important">Tidak FP Masuk</span>
              <label
                class="pl-3 pr-3 bold"
                for="total_keluar"
                style="
                  background-color: green;
                  border-radius: 10px;
                  font-size: 8px !important;
                "
                >{{ this.presensi.data.totalTidakFpMasuk }} hari
              </label>
            </div>
            <b-progress
              :value="this.presensi.data.totalTidakFpMasuk"
              :max="max"
              variant="success"
              animated
              height="8px"
              style="
                margin-top: -2px;
                font-size: 8px;
                background: rgba(255, 255, 255, 0.5) !important;
              "
            ></b-progress>
          </div>
        </div>
        <div class="mt-3">
          <div class="col-sm-12">
            <div class="progress-horz d-flex justify-content-between">
              <span style="font-size: 10px !important">Tidak FP Keluar</span>
              <label
                class="pl-3 pr-3 bold"
                for="total_keluar"
                style="
                  background-color: orange;
                  border-radius: 10px;
                  font-size: 8px !important;
                "
                >{{ this.presensi.data.totalTidakFpKeluar }} hari
              </label>
            </div>
            <b-progress
              :value="this.presensi.data.totalTidakFpKeluar"
              :max="max"
              variant="warning"
              animated
              height="8px"
              style="
                margin-top: -2px;
                font-size: 10px;
                background: rgba(255, 255, 255, 0.5) !important;
              "
            ></b-progress>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.bold {
  font-weight: bold;
}
.f-10 {
  font-size: 10px !important;
}
.f-11 {
  font-size: 11px !important;
}
.f-12 {
  font-size: 12px !important;
}
.f-13 {
  font-size: 13px !important;
}
.f-14 {
  font-size: 14px !important;
}
.title {
  border: none !important;
}
</style>
<script>
import axios from "@/axios";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      presensi: {
        data: {
          bulan: new Date().getMonth() + 1,
          tahun: new Date().getFullYear(),
          totalHadir: 0,
          totalTidakFpMasuk: 0,
          totalTidakFpKeluar: 0,
          totalTidakHadir: 0,
          totalPeriode: 0,
        },
        loading: false,
      },
      token: null,
      gradient: {
        radial: false,
        colors: [
          { color: "#8B1874", offset: "0", opacity: "1" },
          { color: "#BA90C6", offset: "100", opacity: "0.6" },
        ],
      },
      progress: 0,
      max: 0,
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    async getHistoriAbsensi() {
      this.presensi.loading = false;
      let url = "/presenceSecurity/presence_detail";
      let header = {
        headers: {
          Token: this.user.token,
        },
      };
      let payload = new FormData();
      for (let key in this.presensi.data) {
        payload.append(key, this.presensi.data[key]);
      }
      payload.append(
        "periode",
        `${this.presensi.data.bulan}-${this.presensi.data.tahun}`
      );
      payload.append("nik", this.user.data.nik);
      let req = await axios.post(url, payload, header);
      if (req.data.status) {
        let absensi = [];
        let hadir = [];
        let tidakFpMasuk = [];
        let tidakFpKeluar = [];
        req.data.msg.map((x) => {
          let bg =
            x.keterangan == "Hadir"
              ? "bg-card-cus-content-list"
              : "bg-card-cus-pending-list";
          x.bg = bg;
          absensi.push(x);
          if (
            x.keterangan != "Tidak FP Masuk" &&
            x.keterangan != "Tidak FP Keluar" &&
            x.keterangan != "Tidak Hadir"
          ) {
            hadir.push(x);
          } else if (
            x.keterangan == "Tidak FP Masuk" ||
            (x.masuk == "" && x.keluar != "")
          ) {
            tidakFpMasuk.push(x);
          } else if (
            x.keterangan == "Tidak FP Keluar" ||
            (x.masuk != "" && x.keluar == "")
          ) {
            tidakFpKeluar.push(x);
          }
        });
        let hadirr = absensi.length; // hari libur tanggal merah tidak di hitung
        let periode = req.data.jml_hari;
        this.progress = Math.round((hadirr * 100) / periode);
        this.presensi.data.totalPeriode = req.data.jml_hari;
        this.presensi.data.totalHadir = hadirr;
        this.presensi.data.totalTidakFpMasuk = tidakFpMasuk.length;
        this.presensi.data.totalTidakFpKeluar = tidakFpKeluar.length;
        this.presensi.loading = false;
      } else {
        this.presensi.loading = false;
      }
    },
  },
  mounted() {
    this.getHistoriAbsensi();
  },
};
</script>